export const RAW_INGREDIENTS = {
    rawIngredients: [
        {
            name: "Almond",
            src: "./images/ingredientsIcons/almond.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Apple",
            src: "./images/ingredientsIcons/apple.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Bamboo Shoot",
            src: "./images/ingredientsIcons/bambooShoot.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Berry",
            src: "./images/ingredientsIcons/berry.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Bird Egg",
            src: "./images/ingredientsIcons/birdEgg.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Cabbage",
            src: "./images/ingredientsIcons/cabbage.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Calla Lily",
            src: "./images/ingredientsIcons/callaLily.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Carrot",
            src: "./images/ingredientsIcons/carrot.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Chilled Meat",
            src: "./images/ingredientsIcons/chilledMeat.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Crab",
            src: "./images/ingredientsIcons/crab.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Fowl",
            src: "./images/ingredientsIcons/fowl.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Horsetail",
            src: "./images/ingredientsIcons/horsetail.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Jueyun Chili",
            src: "./images/ingredientsIcons/jueyunChili.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Lavender Melon",
            src: "./images/ingredientsIcons/lavenderMelon.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Lotus Head",
            src: "./images/ingredientsIcons/lotusHead.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Matsutake",
            src: "./images/ingredientsIcons/matsutake.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Mint",
            src: "./images/ingredientsIcons/mint.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Mushroom",
            src: "./images/ingredientsIcons/mushroom.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Onion",
            src: "./images/ingredientsIcons/onion.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Pepper",
            src: "./images/ingredientsIcons/pepper.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Pinecone",
            src: "./images/ingredientsIcons/pinecone.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Potato",
            src: "./images/ingredientsIcons/potato.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Qingxin",
            src: "./images/ingredientsIcons/qingxin.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Radish",
            src: "./images/ingredientsIcons/radish.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Rice",
            src: "./images/ingredientsIcons/rice.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Sakura Bloom",
            src: "./images/ingredientsIcons/sakuraBloom.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Salt",
            src: "./images/ingredientsIcons/salt.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Seagrass",
            src: "./images/ingredientsIcons/seagrass.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Shrimp Meat",
            src: "./images/ingredientsIcons/shrimpMeat.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Small Lamp Grass",
            src: "./images/ingredientsIcons/smallLampGrass.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Snapdragon",
            src: "./images/ingredientsIcons/snapdragon.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Sunsettia",
            src: "./images/ingredientsIcons/sunsettia.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Sweet Flower",
            src: "./images/ingredientsIcons/sweetFlower.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Tofu",
            src: "./images/ingredientsIcons/tofu.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Tomato",
            src: "./images/ingredientsIcons/tomato.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Unagi Meat",
            src: "./images/ingredientsIcons/unagiMeat.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Violetgrass",
            src: "./images/ingredientsIcons/violetgrass.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Wheat",
            src: "./images/ingredientsIcons/wheat.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Milk",
            src: "./images/ingredientsIcons/milk.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Fish",
            src: "./images/ingredientsIcons/fish.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Glabrous Beans",
            src: "./images/ingredientsIcons/glabrousBeans.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Sumeru Rose",
            src: "./images/ingredientsIcons/sumeruRose.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Zaytun Peach",
            src: "./images/ingredientsIcons/zaytunPeach.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Rukkhashava Mushrooms",
            src: "./images/ingredientsIcons/rukkhashavaMushrooms.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Starshroom",
            src: "./images/ingredientsIcons/starshroom.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Padisarah",
            src: "./images/ingredientsIcons/padisarah.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Harra Fruit",
            src: "./images/ingredientsIcons/harraFruit.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Ajilenakh Nut",
            src: "./images/ingredientsIcons/ajilenakhNut.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Wolfhook",
            src: "./images/ingredientsIcons/wolfhook.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Mysterious Meat",
            src: "./images/ingredientsIcons/mysteriousMeat.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Coffee Beans",
            src: "./images/ingredientsIcons/coffeeBeans.png",
            rarity: 1,
            obtainedBy: "shop",
        },
        {
            name: "Bulle Fruit",
            src: "./images/ingredientsIcons/bulleFruit.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Marcotte",
            src: "./images/ingredientsIcons/marcotte.png",
            rarity: 1,
            obtainedBy: "forage",
        },
        {
            name: "Fermented Juice",
            src: "./images/ingredientsIcons/fermentedJuice.png",
            rarity: 1,
            obtainedBy: "shop",
        },
    ]
}