export const FOOD_RECIPES_TEMPLATE = "[{\n" +
    "  \"name\": \"Chicken-Mushroom Skewer\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\": \"Teyvat Fried Egg\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\": \"Steak\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Radish Veggie Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Mondstadt Grilled Fish\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Mint Jelly\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Mora Meat\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Stir-Fried Filet\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Grilled Tiger Fish\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Rice Buns\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Egg Roll\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Miso Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sweet Shrimp Sushi\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Bird Egg Sushi\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Flaming Red Bolognese\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sweet Madame\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Northern Smoked Chicken\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Cream Stew\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tea Break Pancake\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fried Radish Balls\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Satisfying Salad\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fisherman's Toast\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Goulash\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Almond Tofu\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Jueyun Chili Chicken\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Jewelry Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Matsutake Meat Rolls\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Crystal Shrimp\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Lotus Seed and Bird Egg Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Noodles with Mountain Delicacies\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Vegetarian Abalone\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Stone Harbor Delicacies\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Mint Salad\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Crab Roe Tofu\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Stir-Fried Fish Noodles\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tuna Sushi\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Onigiri\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Mixed Yakisoba\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Crab Roe Kourayaki\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Golden Shrimp Balls\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Calla Lily Seafood Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sautéed Matsutake\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sticky Honey Roast\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Mushroom Pizza\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Mondstadt Hash Brown\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Pile 'Em Up\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Barbatos Ratatouille\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Adventurer's Breakfast Sandwich\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Northern Apple Stew\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sunshine Sprat\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Crispy Potato Shrimp Platter\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Zhongyuan Chop Suey\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Black-Back Perch Stew\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Qingce Stir Fry\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Come and Get It\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Bamboo Shoot Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Triple-Layered Consommé\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Lotus Flower Crisp\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Universal Peace\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Jueyun Guoba\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fullmoon Egg\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Minty Meat Rolls\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Cured Pork Dry Hotpot\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Stir-Fried Shrimp\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tricolor Dango\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tonkotsu Ramen\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tri-Flavored Skewer\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Imported Poultry\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sakura Mochi\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Konda Cuisine\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Rice Cake Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sakura Tempura\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Rice Pudding\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Oncidium Tofu\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Invigorating Kitty Meal\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Moon Pie\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Jade Parcels\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Golden Crab\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tianshu Meat\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Chicken Tofu Pudding\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Butter Crab\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Chili-Mince Cornbread Buns\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Adeptus' Temptation\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Omelette Rice\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Grilled Unagi Fillet\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Braised Meat\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"More-and-More\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Dry-Braised Salted Fish\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sashimi Platter\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fragrant Mashed Potatoes\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Golden Chicken Burger\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Golden Fried Chicken\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Taiyaki\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Crab, Ham & Veggie Bake\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Cold Cut Platter\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Squirrel Fish\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Soba Noodles\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Radish and Fish Stew\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Berry Mizu Manjuu\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Wakatakeni\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Unagi Chazuke\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Five Pickled Treasures\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sakura Shrimp Crackers\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Dragon Beard Noodles\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Bountiful Year\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sangayaki\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Udon Noodles\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Rainbow Aster\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Katsu Sandwich\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Biryani\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Butter Chicken\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Curry Shrimp\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fish With Cream Sauce\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Lambad Fish Roll\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Masala Cheese Balls\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Meat Lovers' Mushroom Pizza\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Milky Mushroom Crisp Tower\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Minty Bean Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Mushroom Hodgepodge\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Panipuri\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Pita Pocket\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Potato Boat\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Selva Salad\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Shawarma Wrap\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sunset Berry Tea\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tahchin\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tandoori Roast Chicken\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Aaru Mixed Rice\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Baklava\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Candied Ajilenakh Nut\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Charcoal-Baked Ajilenakh Cake\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fruits of the Festival\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Padisarah Pudding\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Rose Custard\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Samosa\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Scented Meat Balls\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Sabz Meat Stew\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Super Magnificent Pizza\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Minty Fruit Tea\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tulumba\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Gilded Tajine\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Jade Fruit Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fatteh\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fruity Duet\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fruity Duet\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fruity Smoothie\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Vessie Chicken\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fruity Trio\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Steak Tartare\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Poissonchant Pie\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Tasses Ragout\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Conch Madeleine\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Ile flottante\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Pate de Fruit\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fish and Chips\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fontainian Onion Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Garlic Baguette\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fontainian Foie Gras\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Poisson Seafood Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Lasagna\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Fontaine Aspic\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Cream of Mushroom Soup\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "},\n" +
    "{\n" +
    "  \"name\":\"Duck Confit\",\n" +
    "  \"qty\":0,\n" +
    "  \"want\":0,\n" +
    "  \"mastery\":false,\n" +
    "  \"curProf\":0,\n" +
    "  \"hasCard\":false,\n" +
    "  \"enabled\":true,\n" +
    "  \"rank\":0\n" +
    "}\n" +
    "]";